<template>
	<CompanyDetail ref="compdetail"></CompanyDetail>
</template>

<script>
	import CompanyDetail from './index1.vue'
	export default {
		components: {
			CompanyDetail,
		},
		data() {
			return {}
		},
		created() {
			this.$nextTick(() => {
				this.$refs.compdetail.renderPage(this.$route.query.id, this.$route.query.zid)
			})
		},
		methods: {}
	}
</script>
<style lang="scss" scoped>

</style>
